import React from "react";
import Layout from "../../components/layout";
import imageSPTT2 from "../../images/SPTT2.jpg";
import SPTTBanner from "../../images/SPTT.jpg";
import SEO from "../../components/seo";

function SPTT() {
  return (
    <Layout>
      <SEO
        keywords={[`Golden`, `Sondagens`, `SPT`, `Construção`]}
        title="Sondagem SPTT"
      />

      <section
        style={{
          height: "300px",
          backgroundImage: `linear-gradient(to right, hsla(0, 0%, 0%, 1), hsla(0, 0%, 0%, 0.3)), url(${SPTTBanner})`,
          backgroundColor: "gray",
          backgroundSize: "cover",
          backgroundPosition: "center",
          backgroundRepeat: "no-repeat",
          backgroundAttachment: "fixed",
        }}
      >
        <h1 className="font-bold text-white m-2 md:ml-8 md:mt-24 uppercase base-title-golden">
          Sondagem à Percussão com Torque (SPT-T)
        </h1>
      </section>

      <div className="grid grid-cols-1 md:grid-cols-2 gap-8 m-2 xl:m-12">
        <div>
          <h3>
            A sondagem a percussão (SPT) é um dos métodos mais utilizados devido
            ao seu alto custo benefício em comparação aos outros métodos. Este
            serviço permite identificar as camadas do solo em questão, assim
            como, o nível do lençol freático, o tipo de solo e a resistência
            NSPT das camadas. Com a análise da sondagem SPT é possível
            determinar uma solução de fundação otimizada para o local, evitando
            futuras patologias e gastos desnecessários.
          </h3>
          <h3 className="my-12">
            Além de serem obtidos os parâmetros que o ensaio SPT proporciona, a
            Golden Tecnologia realiza a medição de torque com a utilização do
            torquímetro, possibilitando a identificação do TR (índice de
            torque), o qual possibilita a medição do atrito lateral do solo,
            tendo assim maior precisão na determinação da resistência do solo
            estudado.
          </h3>
          <h3>
            Com custo benefício vantajoso, a sondagem a percussão com torque
            (SPT-T) apresenta resultados de alta precisão possibilitando ações
            preventivas em caso de problemas identificados.
          </h3>
        </div>
        <img src={imageSPTT2} />
      </div>
    </Layout>
  );
}

export default SPTT;
